<template>
  <info-section
    class="mb-4"
    :total="comments.total"
    :pending="comments.pending"
    icon="mdi-message"
    title="Комментарии"
    :pages="comments.pagesCount"
    :current-page="comments.page"
    @setPage="setPage"
  >
    <comment-card
      v-for="item in comments.list"
      :key="item.id"
      :comment="item"
      class="contact-item"
      @removeComment="removeComment"
    />
    <div v-if="!comments.total && !comments.pending">Нет комментариев</div>
    <template #footer>
      <v-divider></v-divider>
      <v-card-text class="black--text text-body-1 pa-4">
        <comment-form :user-id="talentId" @success="$emit('onSuccess')" />
      </v-card-text>
    </template>
  </info-section>
</template>

<script>
import CommentCard from "@/components/contact/CommentCard.vue";
import CommentForm from "@/components/contact/CommentForm.vue";
import InfoSection from "@/components/InfoSection.vue";

export default {
  name: "CommentsSection",
  components: { InfoSection, CommentForm, CommentCard },
  props: {
    comments: { type: Object, required: true },
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  methods: {
    removeComment(comment) {
      this.$emit("removeComment", comment);
    },
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>
