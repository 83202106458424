<template>
  <div>
    <info-section
      class="mb-4"
      :total="organizations.total"
      :pending="organizations.pending"
      icon="mdi-briefcase"
      title="Организации"
      :pages="organizations.pagesCount"
      :current-page="organizations.page"
      @setPage="setPage"
    >
      <organizations-card
        v-for="organization in organizations.list"
        :key="organization.id"
        :organization="organization"
        class="contact-item"
        allow-list-students
        @showStudents="openMentorForDialog"
      />
      <div v-if="!organizations.list.length && !organizations.pending">
        Нет данных
      </div>
    </info-section>

    <mentor-for-dialog
      v-model="mentorForDialog"
      :organization="selectedMentorsForOrganization"
      :mentor="talentUser"
    />
  </div>
</template>

<script>
import OrganizationsCard from "@/components/contact/OrganizationsCard.vue";
import InfoSection from "@/components/InfoSection.vue";
import MentorForDialog from "@/components/dialogs/MentorFor.vue";

export default {
  name: "OrganizationsSection",
  components: { MentorForDialog, InfoSection, OrganizationsCard },
  props: {
    organizations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedMentorsForOrganization: null,
      mentorForDialog: false,
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
    openMentorForDialog(organization) {
      this.selectedMentorsForOrganization = organization;
      this.mentorForDialog = true;
    },
  },
};
</script>
