var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.organization)?_c('div',[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"flex-grow-1"},[_c('strong',[_vm._v(_vm._s(_vm.organizationName))])]),(_vm.organization.logo)?_c('img',{staticClass:"logo",attrs:{"src":_vm.organization.logo,"alt":"Логотип организации"}}):_vm._e()]),(_vm.eventIds)?_c('div',_vm._l((_vm.eventIds),function(event,idx){return _c('div',{key:event,class:{ 'mt-3': idx !== 0 }},[_c('option-row',{attrs:{"title":"Мероприятие:"}},[_vm._v(" "+_vm._s(_vm.eventsNames[event] || "#" + event)+" ")]),(
          _vm.organization && _vm.organization.events && _vm.organization.events[event]
        )?_c('div',[_c('p',{staticClass:"secondary--text text--lighten-2 mb-1"},[_vm._v("Наставники:")]),_vm._l((_vm.organization.events[event]),function(mentor){return _c('div',{key:mentor},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openMentorDialog(mentor)}}},[(
                _vm.eventMentors[event] &&
                _vm.eventMentors[event][mentor] &&
                _vm.eventMentors[event][mentor].fullName
              )?_c('span',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.eventMentors[event][mentor].fullName)+" ")]):_c('span',{staticClass:"mb-1"},[_vm._v(" #"+_vm._s(mentor)+" "),(_vm.mentorsPending)?_c('span',[_c('v-progress-circular',{attrs:{"size":"18","width":"2","indeterminate":"","color":"primary"}})],1):_vm._e()])])])})],2):_vm._e()],1)}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }