<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <organizations-card :organization="organization" is-dialog />
      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { talentClient } from "@/api";
import OrganizationsCard from "@/components/contact/OrganizationsCard";
export default {
  name: "OrganizationDialog",
  components: { OrganizationsCard },
  props: {
    value: Boolean,
    organizationId: Number,
  },
  data() {
    return {
      organization: null,
      pending: false,
      error: null,
      isOpenDescription: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && this.organizationId) {
          this.getOrganization();
        }
      },
    },
  },
  methods: {
    async getOrganization() {
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/organizations/${this.organizationId}/`,
        });
        if (data) {
          this.organization = data;
        }
      } catch (error) {
        this.error = error.message;
        console.log("error", error);
      }
      this.pending = false;
    },
    handleClose() {
      this.$emit("input", false);
      this.resetData();
    },
    resetData() {
      this.organization = null;
      this.error = null;
      this.pending = false;
      this.isOpenDescription = false;
    },
  },
};
</script>
