<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >Изменить данные пользователя</v-card-title
      >

      <v-card-text>
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <v-container>
            <v-row>
              <v-col cols="12" xs="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="first_name"
                  :rules="{ required: true, max: 50 }"
                  vid="first_name"
                >
                  <v-text-field
                    v-model.trim="form.first_name"
                    :error-messages="errors"
                    label="Имя"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" xs="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="last_name"
                  :rules="{ required: true, max: 150 }"
                  vid="last_name"
                >
                  <v-text-field
                    v-model.trim="form.last_name"
                    :error-messages="errors"
                    label="Фамилия"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="middle_name"
                  :rules="{ required: !form.no_middle_name, max: 100 }"
                  vid="middle_name"
                >
                  <v-text-field
                    v-model.trim="form.middle_name"
                    label="Отчество"
                    :error-messages="errors"
                    :disabled="form.no_middle_name"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" xs="12" sm="6">
                <v-checkbox
                  v-model="form.no_middle_name"
                  label="Нет отчества"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" xs="12" sm="6">
                <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template #activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="birthday"
                      vid="birthday"
                    >
                      <v-text-field
                        :value="form.birthday"
                        :error-messages="errors"
                        readonly
                        label="Дата рождения"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @change="birthdayMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" xs="12" sm="6">
                <p class="text-caption mb-0">Пол</p>
                <v-btn-toggle
                  v-model="form.sex"
                  active-class="primary white--text"
                  mandatory
                  class="d-flex"
                >
                  <v-btn
                    v-for="item in sexOptions"
                    :key="item.title"
                    :value="item.value"
                    height="32"
                    style="flex-basis: 50%"
                    >{{ item.title }}</v-btn
                  >
                </v-btn-toggle>
              </v-col>

              <v-col cols="12" xs="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="address"
                  :rules="{ required: true }"
                  mode="aggressive"
                  vid="address"
                >
                  <address-input
                    v-model.trim="form.address"
                    :error-messages="errors"
                    :initial-value="form.address"
                    label="Адрес"
                  ></address-input>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div v-if="error" class="error--text">
                  <p class="mb-0">Не удалось выполнить операцию. {{ error }}</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="pending"
          :loading="pending"
          text
          @click="handleSubmit"
        >
          Сохранить
        </v-btn>
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressInput from "@/components/contact/AddressInput";
import dayjs from "@/plugins/dayjs";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { talentClient } from "@/api";
const initialForm = () => {
  return {
    first_name: "",
    last_name: "",
    middle_name: "",
    no_middle_name: false,
    address: "",
    birthday: "",
    sex: "",
  };
};
export default {
  name: "EditUserDialog",
  components: {
    AddressInput,
  },
  props: {
    value: Boolean,
    talentUser: {
      type: Object,
    },
  },
  data: () => ({
    form: initialForm(),
    sexOptions: [
      { title: "Мужской", value: "m" },
      { title: "Женский", value: "w" },
    ],
    pending: false,
    error: null,
    birthdayMenu: false,
    date: null,
  }),
  watch: {
    date() {
      this.form.birthday = this.formatBDay(this.date);
    },
    value: {
      immediate: true,
      handler(val) {
        if (val && this.talentUser) {
          this.populateFormData();
        }
      },
    },
  },
  created() {
    this.populateFormData();
  },
  methods: {
    resetData() {
      this.form = initialForm();
      this.pending = false;
      this.error = null;
      this.birthdayMenu = false;
      this.date = null;
      this.$refs.form.reset();
    },
    handleClose() {
      this.$emit("input", false);
      this.resetData();
    },
    formatBDay(date) {
      if (!date) return null;
      return dayjs(date, DATE_DATABASE_FORMAT).format(DATE_DISPLAY_FORMAT);
    },
    populateFormData() {
      const { talentUser } = this;
      if (!talentUser || !talentUser.id) return;
      Object.keys(this.form).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (talentUser.hasOwnProperty(key)) {
          if (key === "birthday") {
            if (talentUser[key]) {
              this.form[key] = dayjs(
                talentUser[key],
                DATE_DATABASE_FORMAT
              ).format(DATE_DISPLAY_FORMAT);
              this.date = dayjs(talentUser[key], DATE_DATABASE_FORMAT).format(
                DATE_DATABASE_FORMAT
              );
            }
          } else {
            this.form[key] = talentUser[key];
          }
        }
      });
    },
    async handleSubmit() {
      const { form } = this.$refs;
      const isValid = await form.validate();
      if (!isValid || this.pending) return;
      this.pending = true;
      this.error = null;
      const payload = { ...this.form };
      payload.birthday = dayjs(payload.birthday, DATE_DISPLAY_FORMAT).format(
        DATE_DATABASE_FORMAT
      );
      if (payload.no_middle_name) {
        payload.middle_name = "";
      }
      try {
        const { data } = await talentClient({
          method: "PATCH",
          url: `/users/${this.talentUser.id}/`,
          data: payload,
        });
        if (data.id) {
          this.$store.commit("talent/UPDATE_USER", data);
        }

        this.handleClose();
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
  },
};
</script>

<style scoped></style>
