<template>
  <div>
    <div v-if="item.value" class="info-option">
      <div class="info-option__label">{{ item.name }}:</div>
      <div v-if="item.type === 'text'">{{ item.value }}</div>
      <div v-else-if="item.type === 'achievement'">
        <span v-if="additionalUserData"
          >{{ additionalUserData.role && additionalUserData.role.name }}.
          <span>{{
            additionalUserData.event && additionalUserData.event.title
          }}</span></span
        >
        <span v-else
          ><v-progress-circular
            v-if="additionalDataPending"
            indeterminate
            color="primary"
            size="16"
            width="2"
          ></v-progress-circular>
          {{ item.value }}</span
        >
      </div>
      <div v-else-if="item.type === 'education'">
        <span v-if="educationTitle">{{ educationTitle }}</span>
        <span v-else
          ><v-progress-circular
            v-if="additionalDataPending"
            indeterminate
            color="primary"
            size="16"
            width="2"
          ></v-progress-circular>
          {{ item.value }}</span
        >
      </div>
      <div v-else-if="item.type === 'organization'">
        <span v-if="organizationTitle">{{ organizationTitle }}</span>
        <span v-else
          ><v-progress-circular
            v-if="additionalDataPending"
            indeterminate
            color="primary"
            size="16"
            width="2"
          ></v-progress-circular>
          {{ item.value }}</span
        >
      </div>
      <div v-else v-html="item.value" />
      <div
        v-if="error && !additionalDataPending"
        class="error--text text-body-2"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { talentClient } from "@/api";

export default {
  name: "QuestionnaireField",
  props: {
    fieldName: String,
    fieldValue: [String, Array, Number],
    eventField: Object,
  },
  data() {
    return {
      item: {},
      pending: false,
      additionalUserData: null,
      error: null,
      additionalDataPending: false,
    };
  },
  computed: {
    educationTitle() {
      const { eventField, additionalUserData } = this;
      if (eventField?.type === "education") {
        return additionalUserData?.title;
      }
      return undefined;
    },
    organizationTitle() {
      const { eventField, additionalUserData } = this;
      if (eventField?.type === "organization") {
        return additionalUserData?.full_name || additionalUserData?.short_name;
      }
      return undefined;
    },
    talentId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { fieldName, fieldValue, eventField } = this;
      this.pending = true;

      const res = {
        name: fieldName,
      };

      if (eventField) {
        switch (eventField.type) {
          case "checkbox":
            res.value = fieldValue.join(", ");
            res.type = eventField.type;
            break;
          case "file":
            res.value = await this.getFile([fieldValue]);
            res.type = eventField.type;
            break;
          case "multiple_files":
            res.value = await this.getFile(fieldValue);
            res.type = "file";
            break;
          case "url":
          case "github":
            res.value = fieldValue
              ? `<a href="${fieldValue}" class="link" target="_blank" rel="noopener noreferrer">${fieldValue}</a>`
              : "";
            res.type = "url";
            break;
          case "achievement":
            res.value = fieldValue;
            res.type = eventField.type;
            this.getAdditionalUserData(
              `/users/${this.talentId}/achievements/${this.fieldValue}/`
            );
            break;
          case "education":
            res.value = fieldValue;
            res.type = eventField.type;
            this.getAdditionalUserData(
              `/users/${this.talentId}/educations/${this.fieldValue}/`
            );
            break;
          case "organization":
            res.value = fieldValue;
            res.type = eventField.type;
            this.getAdditionalUserData(
              `/users/${this.talentId}/organizations/${this.fieldValue}/`
            );
            break;
          default:
            res.value = fieldValue;
            res.type = "text";
            break;
        }
      }
      this.item = res;
      this.pending = false;
    },
    async getFile(list) {
      const res = [];
      try {
        for (let i = 0; i < list.length; i++) {
          const { data } = await talentClient({
            method: "GET",
            url: `/files/${list[i]}/`,
          });
          res.push(
            `<a href="${data.datafile}" class="link" target="_blank" rel="noopener noreferrer">${data.name}</a>`
          );
        }
      } catch (error) {
        console.log("error", error);
      }
      return res.join(", ");
    },
    async getAdditionalUserData(url) {
      this.additionalDataPending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: url,
        });

        if (data.id) {
          this.additionalUserData = data;
        }
      } catch (error) {
        this.error = "Не удалось получить данные";
        //do nothing
        console.log("error", error);
      }
      this.additionalDataPending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-option {
  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 5px;
  }
}
</style>
