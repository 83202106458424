<template>
  <v-card class="mb-4" :loading="pending">
    <v-toolbar color="blue-grey darken-2" dark dense flat>
      <v-toolbar-title class="white--text">
        <v-icon left>mdi-account-switch</v-icon>
        Возможные дубликаты
      </v-toolbar-title>
      <v-chip
        v-if="typeof total === 'number' && !pending"
        :color="total >= 1 ? 'error' : 'blue-grey lighten-4'"
        light
        small
        class="ml-2"
        >{{ total }}</v-chip
      >
    </v-toolbar>

    <v-card-text v-if="pending" class="black--text text-body-1 pa-4">
      Выполняю поиск контактов с идентичными персональными данными...
    </v-card-text>

    <v-card-text v-else-if="!total" class="black--text text-body-1 pa-4">
      Пользователи с идентичными персональными данными не обнаружены
    </v-card-text>

    <v-card-text v-else class="black--text text-body-1 pa-4">
      <div v-for="item in list" :key="item.id" class="mb-4">
        <div>
          <router-link
            target="_blank"
            class="text-decoration-none"
            :to="`/contacts/${item.id}`"
            ><strong>{{ item.fullName }}</strong></router-link
          >
        </div>
        <div class="text-caption">{{ item.infoString }}</div>
        <v-progress-linear
          :value="item.matchLevel"
          color="blue-grey darken-2"
          height="16"
          class="mt-2"
        >
          <template #default="{ value }">
            <strong class="prog-text">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </div>

      <div v-if="total > list.length && searchLink">
        Показаны наиболее релевантные результаты. Смотреть
        <router-link
          target="_blank"
          :to="searchLink"
          class="text-decoration-none"
          >все совпадения</router-link
        >.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { apiClient } from "@/api";
import { getFullName } from "@/utils";
import {
  getUserDuplicateSortWeight,
  isCommonName,
  MAX_SORT_WEIGHT,
} from "@/utils/user";

const MAX_USERS = 6;
const MIN_USERS_FOR_DITAILED_SEARCH = 15;
const USER_PER_PAGE = 30;

export default {
  name: "DuplicateSection",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: true,
      total: 0,
      users: [],
    };
  },
  computed: {
    list() {
      return this.users
        .map((item) => {
          const weight = getUserDuplicateSortWeight(this.user, item);

          const addr = [item.city, item.region_with_type]
            .filter(Boolean)
            .join(", ");

          const infoString = [`id: ${item.id}`, item.birthday, item.email, addr]
            .filter(Boolean)
            .join("; ");
          return {
            ...item,
            sortWeight: weight,
            infoString,
            fullName: getFullName(item),
            matchLevel: (weight / MAX_SORT_WEIGHT) * 100,
          };
        })
        .sort((a, b) => {
          return b.sortWeight - a.sortWeight;
        })
        .slice(0, MAX_USERS);
    },

    searchLink() {
      if (!this.user || !this.user.last_name) {
        return "";
      }
      const search = [this.user.first_name, this.user.last_name]
        .filter(Boolean)
        .join(" ");

      return `/search?search=${search}`;
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    getList(params) {
      return apiClient({
        method: "GET",
        url: `/contacts/`,
        params: {
          ...params,
          limit: params.limit ?? USER_PER_PAGE,
        },
      });
    },
    async handleSearch() {
      const { user } = this;
      const { first_name, last_name, sex, region_with_type, middle_name } =
        user;
      if (!user || !last_name) {
        this.pending = false;
        return;
      }

      let users = [];

      const isCommon = isCommonName({ first_name, last_name });

      const { data } = await this.getList({
        search: `${first_name} ${last_name}`,
        ...(isCommon && sex && { sex }),
        ...(isCommon && region_with_type && { region_with_type }),
      });

      // Если не нашли по полному имени, ищем по фамилии
      // но сужаем выборку по полу и региону
      if (data.results.length < 2) {
        const { data } = await this.getList({
          search: `${last_name}`,
          ...(sex && { sex }),
          ...(region_with_type && { region_with_type }),
        });
        users = data.results;
        this.total = data.count - 1;
      } else if (data.results.length > MIN_USERS_FOR_DITAILED_SEARCH) {
        // если результатов много, то сужаем выборку
        // добавляя отчество и пол
        const { data } = await this.getList({
          search: [first_name, middle_name, last_name]
            .filter(Boolean)
            .join(" "),
          ...(sex && { sex }),
        });
        users = data.results;
        this.total = data.count - 1;
      } else {
        users = data.results;
        this.total = data.count - 1;
      }

      this.users = users.filter((n) => n.id !== user.id);
      this.pending = false;
    },
  },
};
</script>

<style scoped>
.prog-text {
  color: #fff;
  font-size: 10px;
  text-shadow: 1px 1px 2px #000;
}
</style>
