<template>
  <div v-click-outside="handleClickOutside">
    <v-text-field
      v-model="searchValue"
      v-bind="inputProps"
      :hint="hint"
      clearable
      :loading="pending"
      @focus="handleFocus"
    ></v-text-field>
    <v-expand-transition>
      <div v-if="isOpen">
        <div
          v-for="item in searchResults"
          :key="item.unrestricted_value"
          class="addr-option"
          @click="handleSelect(item.unrestricted_value)"
        >
          <div class="pa-2">
            {{ item.value }}
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
/**
 * Этот инпут бегает непосредственно в dadata
 */
export default {
  name: "DadataAddress",
  props: {
    value: String,
    inputProps: {
      type: Object,
      default() {
        return {
          dense: true,
        };
      },
    },
    fromBound: String,
    toBound: String,
  },
  data() {
    return {
      searchValue: "",
      searchResults: [],
      isOpen: false,
      pending: false,
    };
  },
  computed: {
    hint() {
      const { searchValue, searchResults, pending } = this;
      if (!searchValue) return "Начните вводить название";
      if (!searchResults.length && searchValue && !pending)
        return "Нет результатов";
      if (pending && searchValue) return "Ищу адрес...";
      if (searchResults.length && this.isOpen) return "Выберите нужный вариант";
      return "";
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.searchValue = val;
        } else {
          this.searchValue = "";
        }
      },
      immediate: true,
    },
    searchValue: {
      handler(val) {
        // Сбрасываем значение если меняется строка
        if (!val) {
          this.$emit("input", "");
          this.searchResults = [];
          return;
        }
        if (this.value !== val) {
          this.$emit("input", "");
          this.debouncedSearch();
        }
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.searchAddress, 400);
  },
  methods: {
    handleFocus() {
      this.$nextTick(() => {
        this.isOpen = true;
      });
    },
    handleClickOutside() {
      this.$nextTick(() => {
        this.isOpen = false;
      });
    },
    async searchAddress() {
      const { searchValue } = this;
      this.pending = true;
      try {
        const { data } = await axios.request({
          method: "POST",
          url: `/dadata/suggestions/api/4_1/rs/suggest/address`,
          headers: {
            Authorization: `Token ${process.env.VUE_APP_DADATA_API_KEY}`,
          },
          data: {
            query: searchValue,
            ...(this.fromBound && { from_bound: { value: this.fromBound } }),
            ...(this.toBound && { to_bound: { value: this.toBound } }),
          },
        });

        this.searchResults = data.suggestions;
      } catch (error) {
        this._showError(
          `Не удалось получить данные по адресам из DADATA. ${error.message}`
        );
        this.searchResults = [];
      }

      this.pending = false;
    },
    async handleSelect(value) {
      this.pending = true;
      const { data } = await axios.request({
        method: "POST",
        url: `/dadata/suggestions/api/4_1/rs/suggest/address`,
        headers: {
          Authorization: `Token ${process.env.VUE_APP_DADATA_API_KEY}`,
        },
        data: {
          query: value,
          count: 1,
        },
      });
      const result = data.suggestions[0];
      if (result) {
        this.$emit("input", result);
      }
      this.isOpen = false;
      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.addr-option {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #dedede;
  }
}
</style>
