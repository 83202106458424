<template>
  <div>
    <info-section
      class="mb-4"
      :total="records.length"
      :pending="false"
      icon="mdi-view-dashboard-variant"
      title="Воронки"
      :pages="1"
      :current-page="1"
    >
      <boards-refs :records="records" />
      <div v-if="!records.length">
        Данный аккаунт не состоит ни в одной воронке
      </div>

      <template #footer>
        <v-divider></v-divider>
        <v-card-text class="black--text text-body-1 pa-4">
          <v-btn color="primary" outlined @click="assignToBoardDialog = true"
            >Добавить к воронке</v-btn
          >
        </v-card-text>
      </template>
    </info-section>

    <assign-user-to-board v-model="assignToBoardDialog" :talent-id="talentId" />
  </div>
</template>

<script>
import BoardsRefs from "@/components/BoardsRefs.vue";
import InfoSection from "@/components/InfoSection.vue";
import AssignUserToBoard from "@/components/dialogs/AssignUserToBoard.vue";

export default {
  name: "FunnelsSection",
  components: { AssignUserToBoard, InfoSection, BoardsRefs },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      assignToBoardDialog: false,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
};
</script>
