/**
 * Получение всех страниц по заданным параметрам из апи
 * по limit/offset пагинации
 * @param {function} fetcher - функция для получения данных
 * @param {Record<string, any>} params - парметры для передачи в fetcher
 * @param {string=} countProp - название поля с количеством элементов default: `count`
 * @param {string=} resultProp - название поля с результатами default: `results`
 * @returns Массив елементов
 */
export const getAllPages = async (
  fetcher,
  params = {},
  countProp = "count",
  resultProp = "results"
) => {
  if (!("limit" in params)) {
    params.limit = 49;
  }
  if (!("offset" in params)) {
    params.offset = 0;
  }
  const { data: firstPageResults } = await fetcher(params);
  const result = firstPageResults[resultProp] || [];

  if (firstPageResults[countProp] <= params.limit) {
    console.log(firstPageResults[countProp]);
    return result;
  }
  // Количество страниц для запроса
  const pageCount = Math.ceil(firstPageResults[countProp] / params.limit);

  console.log(pageCount, "pageCount");
  const requests = [];

  // Запросы для всех страниц, кроме первой
  // т.к. ее мы уже получили
  for (let i = 1; i < pageCount; i++) {
    requests.push(fetcher({ ...params, offset: params.limit * i }));
  }
  return (await Promise.all(requests)).reduce((acc, { data }) => {
    return [...acc, ...data[resultProp]];
  }, result);
};
