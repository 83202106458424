<template>
  <div v-if="records.length">
    <div v-for="item in records" :key="item.id" class="mb-1">
      <router-link
        :to="{ name: 'board', params: { id: item.column.board.id } }"
        >{{ item.column.board.name }}</router-link
      >
      в колонке <strong>&laquo;{{ item.column.name }}&raquo;</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoardsRefs",
  props: {
    records: {
      type: Array,
    },
  },
};
</script>

<style></style>
