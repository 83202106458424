<template>
  <div>
    <div v-if="provider.title">
      <div class="mb-2 d-flex align-center">
        <img
          :src="provider.icon"
          :alt="provider.title"
          class="social-icon mr-1"
        />
        <strong>{{ provider.title }}</strong>
      </div>
      <v-chip v-if="isLegacy" small color="error" class="mb-2">
        <v-icon left size="18px" title="Удалить тег">mdi-alert</v-icon>
        Устаревший аккаунт</v-chip
      >
      <option-row v-if="social.uid"
        ><span slot="title">UID аккаунта:</span>#{{ social.uid }}</option-row
      >
      <option-row v-if="social.email"
        ><span slot="title">Email аккаунта:</span>{{ social.email }}</option-row
      >
      <option-row v-if="social.first_name"
        ><span slot="title">Имя:</span>{{ social.first_name }}</option-row
      >
      <option-row v-if="social.last_name"
        ><span slot="title">Фамилия:</span>{{ social.last_name }}</option-row
      >
      <option-row v-if="social.username"
        ><span slot="title">Имя пользователя:</span
        >{{ social.username }}</option-row
      >
      <option-row v-if="createDate"
        ><span slot="title">Дата регистрации:</span>{{ createDate }}</option-row
      >
      <option-row v-if="modifyDate"
        ><span slot="title">Дата обновления:</span>{{ modifyDate }}</option-row
      >
      <option-row v-if="authTime"
        ><span slot="title">Дата авторизации:</span>{{ authTime }}</option-row
      >

      <a
        v-if="link"
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >Перейти к аккаунту</a
      >
    </div>
  </div>
</template>

<script>
import { SOCIAL_PROVIDERS, SOCIAL_PROVIDERS_SLUGS } from "@/constants";
import dayjs from "dayjs";
export default {
  name: "SocialCard",
  props: {
    social: {},
  },
  computed: {
    provider() {
      return SOCIAL_PROVIDERS[this.social.provider];
    },
    link() {
      const { social } = this;
      switch (social.provider) {
        case SOCIAL_PROVIDERS_SLUGS.telegram:
          return social.username
            ? `https://telegram.im/@${social.username}`
            : "";
        case SOCIAL_PROVIDERS_SLUGS.kaggle:
          return social.username
            ? `https://www.kaggle.com/${social.username}`
            : "";
        default:
          return social.profile_url;
      }
    },
    createDate() {
      if (!this.social.created_at) return;
      return dayjs(this.social.created_at).format("LLL");
    },
    modifyDate() {
      const modifyDate = this.social.modified || this.social.updated_at;
      if (!modifyDate) return;
      return dayjs(modifyDate).format("LLL");
    },
    authTime() {
      if (!this.social.auth_time) return;
      return dayjs(this.social.auth_time * 1000).format("LLL");
    },
    isLegacy() {
      return this.social.provider.includes("legacy");
    },
  },
};
</script>

<style lang="scss" scoped>
.social-icon {
  width: 24px;
  height: 24px;
}
</style>
