<template>
  <v-dialog
    :value="value"
    max-width="1200px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card class="px-4 pb-4 pt-0 modal-card" :loading="loading">
      <v-container class="mb-0 pb-0">
        <v-card-title class="text-h5 pa-0">Список учеников</v-card-title>
      </v-container>
      <div class="modal-body">
        <template v-if="mentor && organization">
          <v-container>
            <div class="mb-3 px-0">
              <div>
                Наставник: #{{ mentor.id }}
                <strong>{{ mentorFullName }}</strong> ({{ mentor.email }})
                <br />
                Организация: &laquo;<a
                  :href="`${TALENT_HOST}/admin/package/organizationmember/?organization__pk__exact=${organization.id}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Перейти к списку учеников организации"
                  >{{
                    organization.full_name ||
                    organization.short_name ||
                    organization.id
                  }}</a
                >&raquo;
              </div>
            </div>
            <v-row class="mb-3">
              <v-col md="7">
                <v-text-field
                  v-model.trim="query"
                  label="Поиск по ФИО, email, id"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col md="5">
                <v-select
                  v-model="selectedEvent"
                  :items="eventsOptions"
                  label="Мероприятие"
                  item-text="title"
                  placeholder="Выберите мероприятие"
                  item-value="value"
                  clearable
                />
              </v-col>
            </v-row>

            <div v-if="filtredList.length">
              <div class="mb-2">Найдено учеников: {{ filtredList.length }}</div>
              <v-simple-table class="mb-3">
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">ID</th>
                      <th class="text-left">ФИО</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Возраст</th>
                      <th class="text-left">Класс/Курс</th>
                      <th class="text-left">Мероприятия</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filtredList" :key="item.uniqKey">
                      <td>
                        <a
                          href="#"
                          title="Перейти к карточке контакта"
                          @click.prevent="goToUser(item.user.id)"
                          >{{ item.user.id }}</a
                        >
                      </td>
                      <td>
                        {{ item.userFullName || item.user.id }}
                      </td>
                      <td>
                        <a
                          title="Написать письмо пользователю"
                          :href="`mailto:${item.user.email}`"
                          >{{ item.user.email }}</a
                        >
                      </td>
                      <td>
                        {{ item.age || "Не указан" }}
                      </td>
                      <td>
                        {{ item.user.grade || "Не указан" }}
                      </td>
                      <td>
                        <div v-if="item.events">
                          <template v-for="(event, idx) in item.events">
                            <a
                              :key="event"
                              title="Перейти к мероприятию"
                              :href="`${TALENT_HOST}/admin/package/event/${event}/change/`"
                              target="_blank"
                              rel="noopener noreferrer"
                              >{{ event }}</a
                            >
                            <br
                              v-if="idx !== item.events.length - 1"
                              :key="event + '_event'"
                            />
                          </template>
                        </div>
                      </td>
                      <td>
                        <v-btn
                          color="error"
                          :href="`${TALENT_HOST}/admin/package/organizationmember/?organization__pk__exact=${organization.id}&user__pk__exact=${item.user.id}&mentor__pk__exact=${mentor.id}`"
                          title="Перейти в админку таланта чтобы удалить связь"
                          tag="a"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Удалить</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else-if="!loading">
              <div v-if="hasFilters">
                По выбранным параметрам ничего не найдено.
                <a href="#" @click.prevent="handleResetFilters"
                  >Сбросить фильтры</a
                >
              </div>
              <div v-else>Не найдено</div>
            </div>
          </v-container>
        </template>
      </div>
      <v-card-actions class="mt-4 px-0">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Модалка про то, для каких участников переданной организации
 * пользователь является наставником
 */
import { talentClient } from "@/api";
import { getAllPages } from "@/utils/api";
import { getFullName } from "@/utils";
import uniqBy from "lodash/uniqBy";
import dayjs from "@/plugins/dayjs";

const STUDENTS_PER_PAGE = 60;

export default {
  name: "MentorFor",
  props: {
    organization: {
      type: Object,
    },
    /**
     * user-наставник
     */
    mentor: {
      type: Object,
    },
    value: Boolean,
  },
  data() {
    return {
      loading: true,
      list: [],
      query: "",
      selectedEvent: null,
      events: {},
    };
  },
  computed: {
    mentorFullName() {
      if (this.mentor) {
        return getFullName(this.mentor);
      }
      return "";
    },
    hasFilters() {
      return !!this.query || !!this.selectedEvent;
    },
    filtredList() {
      const { list, query, selectedEvent } = this;
      let result = [...list];

      if (selectedEvent) {
        result = list.filter((n) => n.events?.includes(selectedEvent));
      }
      if (!query) {
        return result;
      }
      if (Number.isInteger(+query)) {
        return result.filter((n) => n.user.id === +query);
      }
      const lQuery = query.toLowerCase();
      return result.filter((n) => n.searchTerm.includes(lQuery));
    },
    eventsOptions() {
      const { list, events } = this;

      const opts = list.reduce((acc, item) => {
        if (!item.events?.length) return acc;
        item.events.forEach((event) => {
          if (!acc[event]) {
            acc[event] = {
              value: event,
              title: events[event] || event,
            };
          }
        });
        return acc;
      }, {});

      return [
        {
          value: null,
          title: "Все мероприятия",
        },
        ...Object.values(opts),
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.init();
        } else {
          this.query = "";
          this.loading = true;
          this.list = [];
          this.selectedEvent = null;
        }
      },
    },
  },
  created() {
    this.TALENT_HOST = process.env.VUE_APP_TALENT_HOST;
  },
  methods: {
    async init() {
      const { organization, mentor } = this;
      if (!organization || !mentor) {
        this.list = [];
      }
      this.loading = true;

      const fetcher = (params) => {
        return talentClient({
          method: "GET",
          url: `/users/${mentor.id}/organizations/${organization.id}/member/`,
          params,
        });
      };

      const mentorUsers = await getAllPages(fetcher, {
        mentor: mentor.id,
        only_one_per_user: true,
        limit: STUDENTS_PER_PAGE,
        offset: 0,
      });
      const users = mentorUsers
        .filter((n) => !!n.user)
        .map((item) => {
          const name = getFullName(item.user);
          return {
            ...item,
            userFullName: getFullName(item.user) || item.user?.id,
            uniqKey: `${item.user.id || 0}_${item.events.join("_") || 0}`,
            searchTerm: [name, item.user.email].join(" ").toLowerCase(),
            age: item.user.birthday
              ? dayjs().diff(item.user.birthday, "year")
              : null,
          };
        })
        .sort();
      this.list = uniqBy(users, "uniqKey");
      this.$nextTick(() => {
        this.getEvents();
      });
      this.loading = false;
    },
    goToUser(id) {
      this.$emit("input", false);
      this.$router.push({
        name: "contact",
        params: {
          id,
        },
      });
    },
    async getEvents() {
      const { eventsOptions } = this;
      const ids = eventsOptions.map((n) => n.value).filter((n) => !!n);
      if (!ids.length) return;
      const { data: events } = await talentClient({
        method: "GET",
        url: "/events/",
        params: {
          ids: ids.join(","),
          limit: ids.length,
          offset: 0,
        },
      });
      const result = events.results.reduce((acc, item) => {
        acc[item.id] = item.title;
        return acc;
      }, {});
      this.events = result;
    },
    handleClose() {
      this.$emit("input", false);
    },
    handleResetFilters() {
      this.query = "";
      this.selectedEvent = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  height: 80vh;
  background: #fff;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
.modal-body {
  // min-height: 300px;
  flex-grow: 1;
  // min-height: 80vh;
  overflow: auto;
}
</style>
