import {
  ACHIEVEMENT_STATUS_APPROVED,
  ACHIEVEMENT_STATUS_MODERATION,
  ACHIEVEMENT_STATUS_REJECTED,
} from "@/constants";

export const SORT_OPTIONS = [
  {
    title: "Название (А-Я)",
    value: "event__title",
  },
  {
    title: "Название (Я-А)",
    value: "-event__title",
  },
  {
    title: "Дата (сначала новые)",
    value: "-created_at",
  },
  {
    title: "Дата (сначала старые)",
    value: "created_at",
  },
];

export const STATUS_OPTIONS = [
  {
    title: "Все статусы",
    value: null,
  },
  {
    title: "Подтвержденные",
    value: ACHIEVEMENT_STATUS_APPROVED,
  },
  {
    title: "Отклоненные",
    value: ACHIEVEMENT_STATUS_REJECTED,
  },
  {
    title: "На модерации",
    value: ACHIEVEMENT_STATUS_MODERATION,
  },
];
