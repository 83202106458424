var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.organization)?_c('div',[_c('div',{class:_vm.isDialog ? 'd-flex pl-4 pr-4 pt-4' : 'd-flex mb-2'},[(_vm.isDialog)?_c('div',{staticClass:"text-h5 rm-dialog-title font-weight-bold flex-grow-1"},[_vm._v(" Организация: "+_vm._s(_vm.organizationName)+" ")]):_c('div',{staticClass:"flex-grow-1"},[_c('strong',[_vm._v(_vm._s(_vm.organizationName))])]),(_vm.organization && _vm.organization.logo)?_c('img',{staticClass:"logo",attrs:{"src":_vm.organization.logo,"alt":"Логотип организации"}}):_vm._e()]),(_vm.organization.educational_category)?_c('v-chip',{class:_vm.isDialog ? 'mr-1 ml-4 mt-2' : 'mr-1 mb-1',attrs:{"color":"primary","small":"","outlined":""}},[_vm._v("Образовательная организация")]):_vm._e(),_c('v-card-text',{staticClass:"text-body-1",class:_vm.isDialog ? 'pa-5 black--text' : 'pa-0'},[_c('option-row',{attrs:{"title":"ID Организации:"}},[_c('router-link',{staticClass:"talent-link",attrs:{"to":{
          name: 'organization',
          params: { orgId: _vm.organization.id },
        }}},[_vm._v(" "+_vm._s(_vm.organization.id)),_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-open-in-new")])],1)],1),(_vm.organization.description && _vm.isDialog)?_c('v-expansion-panels',{staticClass:"black--text text-body-1 pa-0 mb-1",attrs:{"flat":""}},[_c('v-expansion-panel',{on:{"change":function($event){_vm.isOpenDescription = !_vm.isOpenDescription}}},[_c('v-expansion-panel-header',{staticClass:"text-body-1 d-block pa-0",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.isOpenDescription ? "Скрыть" : "Показать")+" ")])]},proxy:true}],null,false,2263856614)},[_c('span',{staticClass:"secondary--text text--lighten-2 mr-1"},[_vm._v("Описание:")])]),_c('v-expansion-panel-content',[_vm._v(" "+_vm._s(_vm.organization.description)+" ")])],1)],1):_vm._e(),(_vm.address)?_c('option-row',{attrs:{"title":"Адрес:"}},[_vm._v(_vm._s(_vm.address))]):_vm._e(),(_vm.contactFullName)?_c('option-row',{attrs:{"title":"Контактное лицо:"}},[_vm._v(_vm._s(_vm.contactFullName))]):_vm._e(),(_vm.organization.contact_email)?_c('option-row',{attrs:{"title":"Эл.почта:"}},[_c('a',{attrs:{"href":`mailto:${_vm.organization.contact_email}`}},[_vm._v(_vm._s(_vm.organization.contact_email))])]):_vm._e(),(_vm.organization.contact_phone && _vm.isDialog)?_c('option-row',{attrs:{"title":"Телефон контактного лица:"}},[_vm._v(_vm._s(_vm.organization.contact_phone))]):_vm._e(),(_vm.createDate)?_c('option-row',{attrs:{"title":"Дата создания:"}},[_vm._v(_vm._s(_vm.createDate))]):_vm._e(),(_vm.updatedAtDate && _vm.isDialog)?_c('option-row',{attrs:{"title":"Дата обновления:"}},[_vm._v(_vm._s(_vm.updatedAtDate))]):_vm._e(),(_vm.organization.parent_organization)?_c('option-row',{attrs:{"title":"Родительская организация:"}},[_c('router-link',{staticClass:"talent-link",attrs:{"to":{
          name: 'organization',
          params: { orgId: _vm.organization.parent_organization },
        }}},[_vm._v(" "+_vm._s(_vm.organization.parent_organization)),_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-open-in-new")])],1)],1):_vm._e(),(_vm.organization.legal_entity)?_c('option-row',{attrs:{"title":"Юр. Лицо:"}},[_c('talent-link',{attrs:{"path":`admin/package/legalentity/${_vm.organization.legal_entity}/change/`}},[_vm._v(" "+_vm._s(_vm.organization.legal_entity)+" ")])],1):_vm._e(),_c('option-row',{attrs:{"title":"Модерация пройдена:"}},[_vm._v(" "+_vm._s(_vm.organization.is_permitted ? "Да" : "Нет")+" ")]),(_vm.organization.is_representative)?_c('option-row',{attrs:{"title":"Региональный представитель:"}},[_vm._v(" Данная организация является рег. представителем ")]):_vm._e(),(
        _vm.organization.representatives && !!_vm.organization.representatives.length
      )?_c('option-row',{attrs:{"title":"Региональные представители:"}},[_vm._l((_vm.organization.representatives),function(item,idx){return [_c('router-link',{key:item,staticClass:"talent-link",attrs:{"to":{
            name: 'organization',
            params: { orgId: item },
          }}},[_vm._v(" "+_vm._s(item)),_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-open-in-new")])],1),(idx + 1 !== _vm.organization.representatives.length)?_c('span',{key:item + 'commma'},[_vm._v(", ")]):_vm._e()]})],2):_vm._e(),(_vm.organization.id)?_c('option-row',{attrs:{"title":"К организации:"}},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'organization', params: { orgId: _vm.organization.id } }}},[_vm._v("Перейти")])],1):_vm._e(),(_vm.allowListStudents)?_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('showStudents', _vm.organization)}}},[_vm._v("Смотреть список")]),_vm._v(" учеников наставника ")]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }