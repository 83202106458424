<template>
  <info-section
    class="mb-4"
    :total="tags.length"
    :pending="false"
    icon="mdi-tag"
    title="Теги"
    :pages="1"
    :current-page="1"
  >
    <v-chip
      v-for="item in tags"
      :key="item.id"
      :color="item.color"
      class="mr-2 mb-2"
      dark
      small
      :title="`Показать всех пользователей с тегом «${item.name}»`"
      @click.stop="
        $router.push({
          name: 'search',
          query: {
            tags_ids: item.id,
            search: '',
          },
        })
      "
    >
      {{ item.name }}
      <v-icon
        right
        size="18px"
        title="Удалить тег"
        @click.stop="$emit('handleRemoveTag', item)"
        >mdi-close</v-icon
      >
    </v-chip>
    <div v-if="!tags.length">Нет тегов</div>
    <template #footer>
      <v-divider></v-divider>
      <v-card-text class="black--text text-body-1 pa-4">
        <tag-search :selected-tags="tags" @tagClick="handleTagClick" />
      </v-card-text>
    </template>
  </info-section>
</template>

<script>
import TagSearch from "@/components/TagSearch.vue";
import InfoSection from "@/components/InfoSection.vue";

export default {
  name: "TagsSection",
  components: { InfoSection, TagSearch },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleTagClick(tag) {
      this.$emit("handleTagClick", tag);
    },
  },
};
</script>
