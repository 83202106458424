<template>
  <v-sheet v-if="user" color="white" elevation="1" class="pa-4">
    <h3>Основные контакты</h3>

    <div class="mb-1">
      Эл. почта: <a :href="`mailto:${user.email}`">{{ user.email }}</a>
    </div>
    <div>
      Телефон: <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
    </div>
    <v-divider class="my-4" />

    <div>
      <h3>Ссылки на соц.сети</h3>
      <div class="social-links mt-2">
        <v-btn
          v-for="item in socialLinks"
          :key="item.id"
          :href="item.url"
          target="_blank"
          rel="noopener noreferrer"
          :title="item.url"
          color="teal lighten-5"
          class="mx-2 social-btn"
          small
          elevation="0"
          light
          fab
        >
          <img width="24px" :src="item.icon" :alt="item.title" />
        </v-btn>
      </div>

      <div v-if="!socialLinks.length">Нет данных</div>
    </div>

    <v-divider class="my-4" />

    <div>
      <h3>Дополнительные подтвержденные Email адреса</h3>
      <div v-for="item in extraEmails" :key="item.id" class="mb-1">
        <a :href="`mailto:${item.email}`"
          >{{ item.email }}(emailObjectId: {{ item.id }}))</a
        >
      </div>
      <div v-if="!extraEmails.length">Нет данных</div>
    </div>
  </v-sheet>
</template>

<script>
import { talentClient } from "@/api";
import { SOCIAL_PROVIDERS } from "@/constants";
export default {
  name: "UserContacts",
  props: {
    userId: {
      type: Number,
      required: true,
    },
    user: {
      type: Object,
      requred: true,
    },
    socials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      extraEmails: [],
    };
  },
  computed: {
    socialLinks() {
      return this.socials
        .filter((n) => n.profile_url && SOCIAL_PROVIDERS[n.provider])
        .map((n) => {
          return {
            title: n.provider,
            url: n.profile_url,
            id: n.id,
            icon: SOCIAL_PROVIDERS[n.provider].icon,
          };
        });
    },
  },
  mounted() {
    this.getExtraEmails();
  },
  methods: {
    async getExtraEmails() {
      if (!this.user) return;
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${this.userId}/emails/`,
        params: {
          limit: 100,
          offset: 0,
        },
      });
      this.extraEmails = data.results;
    },
  },
};
</script>

<style lang="scss">
.social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  flex-wrap: wrap;

  .social-btn {
    flex-grow: 0;
  }
}
</style>
