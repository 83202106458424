<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="primary" dark dense flat>
        <v-toolbar-title class="white--text"
          >Интересы пользователя</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="black--text text-body-1 pa-4">
        {{ list }}
        <div v-if="!list.length">Интересы не указаны</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { talentClient } from "@/api/index";
export default {
  name: "InterestsSection",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userInterests: [],
    };
  },
  computed: {
    allInterests() {
      return this.$store.state.talent.interests.reduce((acc, value) => {
        acc[value.id] = value.title;
        return acc;
      }, {});
    },
    list() {
      const { allInterests, userInterests } = this;
      const list = userInterests.map((n) => {
        return allInterests[n.interest] || `id: ${n.interest}`;
      });
      return list.join(", ");
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      await this.$store.dispatch("talent/getInterests");
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${this.userId}/user-interests/`,
      });
      this.userInterests = data.results;
    },
  },
};
</script>

<style></style>
