<template>
  <div>
    <info-section
      class="mb-4"
      :total="membersOrganizations.total"
      :pending="membersOrganizations.pending"
      icon="mdi-account-school"
      title="Наставники"
      :pages="membersOrganizationsPageCount"
      :current-page="membersOrganizations.page"
      @setPage="setPage"
    >
      <organization-mentors-card
        v-for="organization in displayedMembersOrganizations"
        :key="organization.orgId + 'member'"
        :organization="organization"
        class="contact-item"
        @openMentorDialog="openMentorDialog"
      />
      <div
        v-if="
          !membersOrganizations.list.length && !membersOrganizations.pending
        "
      >
        Нет данных
      </div>
    </info-section>

    <v-dialog v-model="contactDialog" max-width="600px">
      <contact-dialog
        :is-active="contactDialog"
        :talent-id="selectedMentorId"
        @close="contactDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import OrganizationMentorsCard from "@/components/contact/OrganizationMentorsCard.vue";
import InfoSection from "@/components/InfoSection.vue";
import ContactDialog from "@/components/dialogs/ContactDialog.vue";

export default {
  name: "MentorsSection",
  components: { ContactDialog, InfoSection, OrganizationMentorsCard },
  props: {
    membersOrganizations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactDialog: false,
      selectedMentorId: null,
    };
  },
  computed: {
    displayedMembersOrganizations() {
      const { membersOrganizations } = this;
      const start =
        (membersOrganizations.page - 1) * membersOrganizations.limit;
      const end = start + membersOrganizations.limit;
      return membersOrganizations.list.slice(start, end);
    },
    membersOrganizationsPageCount() {
      return Math.ceil(
        this.membersOrganizations.list.length / this.membersOrganizations.limit
      );
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
    openMentorDialog(mentorId) {
      this.selectedMentorId = mentorId;
      this.contactDialog = true;
    },
  },
};
</script>
