<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card v-if="request" :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >Заявка на мероприятие #{{ request.id }}</v-card-title
      >
      <v-card-text class="pa-5 black--text text-body-1">
        <request-card
          :key="request.id"
          :request="request"
          :full-request="fullRequest"
          @openTeamDialog="openTeamDialog"
        />
        <div v-if="project">
          Проект:
          <a
            href="#"
            class="link"
            @click.stop.prevent="$emit('openProjectDialog', project.id)"
          >
            <span v-if="pending"
              ><v-progress-circular
                size="18"
                width="2"
                indeterminate
                color="primary"
              ></v-progress-circular></span
            ><span v-else>{{
              project && project.title ? project.title : "#" + project.id
            }}</span>
          </a>
        </div>

        <v-card v-if="showQuestionnaire">
          <v-toolbar color="blue-grey darken-2 mt-5" dark dense flat>
            <v-toolbar-title class="white--text">Анкета </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <questionnaire-field
              v-for="(item, idx) in Object.keys(fullRequest.fields)"
              :key="idx"
              class="questionnaire"
              :field-name="item"
              :field-value="fullRequest.fields[item]"
              :event-field="structuredEventFields[item]"
            />
          </v-card-text>
        </v-card>

        <v-card v-if="showProject">
          <v-toolbar color="blue-grey darken-2 mt-5" dark dense flat>
            <v-toolbar-title class="white--text">Проект</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="black--text text-body-1">
            <project-card :project="project" class="contact-item" />
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RequestCard from "@/components/contact/RequestCard";
import ProjectCard from "@/components/contact/ProjectCard";
import { talentClient } from "@/api";
import QuestionnaireField from "@/components/dialogs/QuestionnaireField";
export default {
  name: "RequestDialog",
  components: { QuestionnaireField, RequestCard, ProjectCard },
  props: {
    request: {
      type: Object,
    },
    value: Boolean,
  },
  data() {
    return {
      eventFields: [],
      eventLimits: {},
      fullRequest: {},
      pending: false,
      project: null,
    };
  },
  computed: {
    showQuestionnaire() {
      const { fullRequest, eventFields } = this;
      if (!eventFields || !fullRequest.fields) return;
      const hasRequestFields = Object.keys(fullRequest.fields)?.length > 0;
      return hasRequestFields && !!eventFields.length;
    },
    talentId() {
      return +this.$route.params.id;
    },
    showProject() {
      const { eventLimits, project } = this;
      if (!project) return;
      return eventLimits.requires_project && project.id;
    },
    structuredEventFields() {
      return this.eventFields
        .filter((i) => !i.hidden)
        .reduce((acc, val) => {
          acc[val.name] = val;
          return acc;
        }, {});
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && this.request) {
          this.init();
        }
      },
    },
  },
  methods: {
    async init() {
      if (this.pending || !this.request) return;
      try {
        this.pending = true;
        await Promise.all([
          this.getFullRequest(),
          this.getEventFieldsAndLimits(),
        ]);

        if (this.eventLimits?.requires_project) {
          this.getProject();
        }
      } catch (error) {
        console.log("error", error);
      }
      this.pending = false;
    },
    handleClose() {
      this.$emit("input", false);
      this.resetData();
    },
    resetData() {
      this.eventFields = [];
      this.eventLimits = {};
      this.fullRequest = {};
      this.pending = false;
      this.project = null;
    },
    async getEventFieldsAndLimits() {
      const { data } = await talentClient({
        method: "GET",
        url: `/events/${this.request.event.id}/`,
      });
      if (data.id) {
        this.eventFields = data.fields;
        this.eventLimits = data.limits;
      }
    },
    async getFullRequest() {
      if (!this.request?.id) return;
      const { data } = await talentClient({
        method: "GET",
        url: `/users/${this.talentId}/requests/${this.request.id}/`,
      });
      if (data.id) {
        this.fullRequest = data;
      }
    },
    async getProject() {
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${this.talentId}/projects/`,
          params: {
            events: this.request.event?.id,
          },
        });
        if (data.count) {
          this.project = data.results[0];
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    openTeamDialog(teamId) {
      this.$emit("openTeamDialog", teamId);
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire + .questionnaire {
  padding-top: 10px;
  border-top: 1px solid #c0c0c0;
  margin-top: 10px;
}
</style>
