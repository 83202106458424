import { getFullName } from ".";
import commonNames from "@/data/commonnames.json";
const USER_SORT_WEIGHTS = {
  // full
  birthday: 4,
  fio: 3,
  address: 1,
  email: 4,
  // partial
  city: 1,
  region_with_type: 1,
  birth_day_and_month: 3,
  last_name: 2,
  first_name: 1,
  middle_name: 0.5,
  year: 1,
  month: 1,
  day: 1,
};

export const MAX_SORT_WEIGHT =
  USER_SORT_WEIGHTS.birthday +
  USER_SORT_WEIGHTS.fio +
  USER_SORT_WEIGHTS.address +
  USER_SORT_WEIGHTS.email;

export const MATCH_LEVEL_EXTRA_HIGHT = 6;
export const MATCH_LEVEL_HIGHT = 5;
export const MATCH_LEVEL_MEDIUM = 2;
export const MATCH_LEVEL_LOW = 3;

const getAddressWeight = (user, targetUser) => {
  const { region_with_type, city, address } = user;
  const {
    region_with_type: targetRegion,
    city: targetCity,
    address: targetAddress,
  } = targetUser;
  let result = 0;

  if (address && address === targetAddress) {
    return USER_SORT_WEIGHTS.address;
  }
  if (region_with_type && region_with_type === targetRegion) {
    result += USER_SORT_WEIGHTS.region_with_type;
  }
  if (city && city === targetCity) {
    result += USER_SORT_WEIGHTS.city;
  }
  return result;
};

const getFioWeights = (user, targetUser) => {
  const { first_name, last_name, middle_name } = user;
  const {
    first_name: targetFirstName,
    last_name: targetLastName,
    middle_name: targetMiddleName,
  } = targetUser;
  let result = 0;
  const fio = getFullName(user);
  const targetFio = getFullName(targetUser);

  if (fio.length && fio === targetFio) {
    return USER_SORT_WEIGHTS.fio;
  }

  if (first_name && first_name === targetFirstName) {
    result += USER_SORT_WEIGHTS.first_name;
  }
  if (last_name && last_name === targetLastName) {
    result += USER_SORT_WEIGHTS.last_name;
  }
  if (middle_name && middle_name === targetMiddleName) {
    result += USER_SORT_WEIGHTS.middle_name;
  }
  return result;
};

const getBdayWeights = (user, targetUser) => {
  const { birthday } = user;
  const { birthday: targetBirthday } = targetUser;
  let result = 0;
  if (birthday && birthday === targetBirthday) {
    return USER_SORT_WEIGHTS.birthday;
  }

  if (!targetBirthday || !birthday) return 0;

  const [year, month, day] = birthday.split("-");
  const [year2, month2, day2] = targetBirthday.split("-");

  if (year === year2) {
    result += USER_SORT_WEIGHTS.year;
  }

  if (month === month2 && day === day2) {
    result += USER_SORT_WEIGHTS.birth_day_and_month;
  } else {
    if (month === month2) {
      result += USER_SORT_WEIGHTS.month;
    }
    if (day === day2) {
      result += USER_SORT_WEIGHTS.day;
    }
  }

  return result;
};

function stringSimilarity(a, b) {
  if (!a || !b) {
    return 0;
  }
  const longerFirst = a.length >= b.length;
  let equivalency = 0;
  const minLength = longerFirst ? b.length : a.length;
  const maxLength = !longerFirst ? b.length : a.length;

  if ((longerFirst && a.includes(b)) || (!longerFirst && b.includes(a))) {
    return 1;
  }

  for (var i = 0; i < minLength; i++) {
    if (a[i] == b[i]) {
      equivalency++;
    }
  }
  return equivalency / maxLength;
}

const getEmailWeight = (email1, email2) => {
  if (!email1 || !email2) {
    return 0;
  }
  const str1 = email1.split("@")[0] || "";
  const str2 = email2.split("@")[0] || "";
  return stringSimilarity(str1, str2) * USER_SORT_WEIGHTS.email;
};

export const getUserDuplicateSortWeight = (user, targetUser) => {
  if (!user || !targetUser) return 0;
  const w1 = getAddressWeight(user, targetUser);
  const w2 = getFioWeights(user, targetUser);
  const w3 = getBdayWeights(user, targetUser);
  const w4 = getEmailWeight(user.email, targetUser.email);
  return w1 + w2 + w3 + w4;
};

export const isCommonName = ({ first_name, last_name }) => {
  if (!first_name || !last_name) return false;
  const str = `${first_name} ${last_name}`;
  return commonNames.includes(str);
};
