<template>
  <info-section
    class="mb-4"
    :total="projects.total"
    :pending="projects.pending"
    icon="mdi-projector"
    title="Проекты"
    :pages="projects.pagesCount"
    :current-page="projects.page"
    @setPage="setPage"
  >
    <template v-for="project in projects.list">
      <v-hover v-slot="{ hover }" :key="project.id">
        <project-card
          :class="{ 'on-hover': hover }"
          :project="project"
          class="contact-item"
          @click.native="$emit('openProjectDialog', project.id)"
        />
      </v-hover>
    </template>
    <div v-if="!projects.list.length && !projects.pending">Нет проектов</div>
  </info-section>
</template>

<script>
import ProjectCard from "@/components/contact/ProjectCard.vue";
import InfoSection from "@/components/InfoSection.vue";

export default {
  name: "ProjectsSection",
  components: { InfoSection, ProjectCard },
  props: {
    projects: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setPage", page);
    },
  },
};
</script>
