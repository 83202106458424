<template>
  <div v-if="education">
    <div class="mb-2">
      <strong>{{ education.title }}</strong>
    </div>
    <div>
      <v-chip v-if="education.current" color="primary" small class="mr-1 mb-1"
        >Текущее</v-chip
      >
      <v-chip color="primary" small outlined class="mr-1 mb-1">{{
        category
      }}</v-chip>
      <v-chip v-if="education.dadata_hid" color="error" small class="mr-1 mb-1"
        >DaData</v-chip
      >
      <v-chip v-else color="error" outlined small class="mr-1 mb-1"
        >Не справочное</v-chip
      >
    </div>
    <option-row v-if="education.extra.faculty"
      ><span slot="title">Факультет:</span
      >{{ education.extra.faculty }}</option-row
    >
    <option-row v-if="education.extra.speciality"
      ><span slot="title">Специальность:</span
      >{{ education.extra.speciality }}</option-row
    >
    <option-row v-if="education.extra.academic_degree"
      ><span slot="title">Степень:</span
      >{{ education.extra.academic_degree }}</option-row
    >
    <option-row
      ><span slot="title">Адрес:</span>{{ education.address }}</option-row
    >
    <option-row
      ><span slot="title">Класс/Курс:</span>{{ education.grade }}</option-row
    >
    <option-row v-if="education.organization"
      ><span slot="title">Организация:</span
      ><a href="#" class="link" @click.stop.prevent="openOrganizationDialog">
        <span v-if="pending"
          ><v-progress-circular
            size="18"
            width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </span>
        <span v-else>{{ organizationName }}</span>
      </a></option-row
    >
  </div>
</template>

<script>
import { talentClient } from "@/api";

/**
 * {
  "id": 0,
  "country": "",
  "federal_district": "",
  "region_with_type": "",
  "city": "",
  "settlement": "",
  "city_district_with_type": "",
  "address": "",
  "city_type_full": "",
  "settlement_type_full": "",
  "geo_lat": "",
  "geo_lon": "",
  "created_at": "2019-08-24T14:15:22Z",
  "updated_at": "2019-08-24T14:15:22Z",
  "category": "high",
  "grade": 1,
  "title": "string",
  "full_name": "string",
  "dadata_hid": "string",
  "ogrn": "string",
  "inn": "string",
  "oktmo": "string",
  "okato": "string",
  "start": 1970,
  "end": 1970,
  "extra": {
    "property1": null,
    "property2": null
  },
  "current": true,
  "geo_data": 0,
  "person": 0,
  "organization": 0
}
 */
export default {
  name: "EductionCard",
  props: {
    education: {
      type: Object,
    },
  },
  data() {
    return {
      organization: null,
      pending: false,
    };
  },
  computed: {
    organizationName() {
      return this.organization?.full_name || this.organization?.short_name
        ? this.organization.full_name || this.organization.short_name
        : "#" + this.education.team;
    },
    category() {
      switch (this.education.category) {
        case "secondary":
          return "Среднее образование";
        case "high":
          return "Высшее образование";
        case "vocational":
          return "Среднее-профессиональное";
        default:
          return "Неизвестный тип";
      }
    },
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    async getOrganization() {
      if (!this.education || !this.education.organization) return;
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/organizations/${this.education.organization}/`,
        });
        if (data.id) {
          this.organization = data;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.pending = false;
    },
    openOrganizationDialog() {
      if (this.education && this.education.organization) {
        this.$emit("openOrganizationDialog", this.education.organization);
      }
    },
  },
};
</script>
