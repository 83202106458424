<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5 rm-dialog-title"
        >{{ isEdit ? "Редактировать" : "Добавить" }} место работы</v-card-title
      >
      <v-card-text class="black--text">
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, min: 3, max: 250 }"
          >
            <v-text-field
              v-model="name"
              label="Название компании"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, min: 3, max: 50 }"
          >
            <v-text-field
              v-model="position"
              label="Должность"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="address"
            :rules="{ required: true }"
          >
            <work-place-address
              v-model="address"
              from-bound="city"
              to-bound="settlement"
              :input-props="{
                label: 'Город или населенный пункт',
                errorMessages: errors,
              }"
            ></work-place-address>
          </validation-provider>
        </validation-observer>
        <div v-if="error" class="mt-3 error--text">
          Не удалось сохранить место работы. {{ error }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="pending" @click="handleSubmit">{{
          isEdit ? "Сохранить" : "Добавить"
        }}</v-btn>
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiClient } from "@/api";
import WorkPlaceAddress from "@/components/WorkPlaceAddress";
import { pathJoin } from "@/api/utils";
const initialState = () => {
  return {
    name: "",
    position: "",
    city: "",
    settlement: "",
    pending: false,
    searchPending: false,
    error: "",
  };
};
export default {
  name: "WorkPlaceDialog",
  components: {
    WorkPlaceAddress,
  },
  props: {
    value: Boolean,
    talentId: {
      type: Number,
    },
    workplace: {
      type: Object,
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    address: {
      get() {
        return [this.city, this.settlement].filter(Boolean).join(", ");
      },
      set(value) {
        this.city = value?.data?.city;
        this.settlement = value?.data?.settlement;
      },
    },
    isEdit() {
      return !!this.workplace?.id;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.mapPropsToForm();
        } else {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
      this.$refs.form.reset();
    },
    handleClose() {
      this.$emit("input", false);
    },
    mapPropsToForm() {
      const { workplace } = this;
      console.log("");
      if (!workplace) return;
      this.name = workplace.name;
      this.settlement = workplace.settlement;
      this.city = workplace.city;
      this.position = workplace.position;
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const { isEdit } = this;
      this.pending = true;
      this.error = "";
      const config = {
        method: "POST",
        url: `/contacts/${this.talentId}/workplaces`,
        data: {
          city: this.city,
          current: isEdit ? this.workplace.current : true,
          name: this.name,
          position: this.position,
          settlement: this.settlement,
        },
      };

      if (isEdit) {
        config.method = "PATCH";
        config.url = pathJoin(config.url, this.workplace.id);
      }

      console.log("config", config);
      try {
        const { data } = await apiClient(config);
        this.$emit("onSubmit", data);
        this.reset();
        this.$emit("input", false);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
